<template>
    <div class="p-[16px_20px] border-b-[1px] border-b-[#E2E4E9]">
        <span class="block text-base text-[#0A0D14] font-medium mb-[20px]">Remove this profile from the list?</span>
        <span class="block text-base text-[#4D5761]">“{{ store.currentProfile.name }}” will be removed.</span>
    </div>
    <div class="p-[16px_20px] flex gap-[12px]">
        <button class="button-secondary w-full" @click="onCancel()">Cancel</button>
        <button class="button-primary w-full" @click="onDelete()">Delete</button>
    </div>
</template>
<script>
import useListStore from '@/stores/List';

export default {
    setup: () => ({
        store: useListStore()
    }),
    props: {
        interface: {
            type: Object,
            required: true
        },
        params: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.interface.modal.setHeader();
    },
    methods: {
        onCancel() {
            this.interface.modal.close();
        },
        async onDelete() {
            const data = { profile_id: this.store.currentProfile.id };
            await this.store.removeProfile(this.store.currentList.id, data);
            
            this.interface.notify('The profile was removed successfully.');
            this.interface.modal.close();
        }
    }
}
</script>